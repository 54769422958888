import '@gate31/faro/src';
import Accordion from '@gate31/uikit/common/components/accordion/accordion';
import { SlimBannerDesk } from '@gate31/desktop/components/SlimBannerDesk/SlimBannerDesk';
import { HeaderWrapperDesk } from '@gate31/desktop/components/HeaderWrapperDesk/HeaderWrapperDesk';
import { HeaderDesk } from '@gate31/desktop/components/HeaderDesk/HeaderDesk';
import { InstagramDesk } from '@gate31/desktop/components/InstagramDesk/InstagramDesk';
import { ShopDesk } from '@gate31/desktop/components/ShopDesk/ShopDesk';
import { ReviewsPage } from '@gate31/desktop/scripts/ReviewsPage';
import Client from '@gate31/uikit/common/components/client/client';
import { ClientResponseInt } from '@gate31/types/client';
import { EditSubscriptionPageWrapperDesk } from '../components/EditSubscriptionsDesk/EditSubscriptionPageWrapperDesk/EditSubscriptionPageWrapperDesk';
import { SnippetProductSliderDesk } from '../components/SnippetProductSliderDesk/SnippetProductSliderDesk';
import { ManufacturinSliderDesk } from '../components/ManufacturinSliderDesk/ManufacturinSliderDesk';
import { SelectDesk } from '../components/SelectDesk/SelectDesk';
import { MainBannerDesk } from '../components/MainBannerDesk/MainBannerDesk';
import { ShopPointsDesk } from '../components/ShopPointsDesk/ShopPointsDesk';
import { ProductsSliderDesk } from '../components/ProductsSliderDesk/ProductsSliderDesk';
import { HeaderPopoverDesk } from '../components/HeaderPopoverDesk/HeaderPopoverDesk';
import { TickerDesk } from '../components/TickerDesk/TickerDesk';
import { CartManagerDesk } from '../components/CartManagerDesk/CartManagerDesk';
import { CartPopoverDesk } from '../components/CartPopoverDesk/CartPopoverDesk';
import { CartPopoverSnippetDesk } from '../components/CartPopoverSnippetDesk/CartPopoverSnippetDesk';
import { AccordionDesk } from '../components/AccordionDesk/AccordionDesk';
import FavoriteDesk from '../components/FavoriteDesk/FavoriteDesk';
import FavoritePageDesk from '../components/FavoritePageDesk/FavoritePageDesk';
import { CookieDesk } from '../components/CookieDesk/CookieDesk';
import { SubscriptionModalDesk } from '../components/SubscriptionModalDesk/SubscriptionModalDesk';
import { FooterDesk } from '../components/FooterDesk/FooterDesk';
import AccountAuthDesk from '../components/AccountAuthDesk/AccountAuthDesk';
import CreateAccountDesk from '../components/CreateAccountDesk/CreateAccountDesk';
import { SearchBoxPopoverDesk } from '../components/SearchBoxPopoverDesk/SearchBoxPopoverDesk';
import { SearchBoxDesk } from '../components/SearchBoxDesk/SearchBoxDesk';
import { SearchBoxManagerDesk } from '../components/SearchBoxManagerDesk/SearchBoxManagerDesk';
import { AccountClientDesk } from '../components/AccountClientDesk/AccountClientDesk';
import { ScrollTopDesk } from '../components/ScrollTopDesk/ScrollTopDesk';
import { SubscribeDesk } from '../components/SubscribeDesk/SubscribeDesk';
import { CollectionPage } from './CollectionPage';
import { Cart } from './pages/cart';

function init() {
    const headerWrapper = HeaderWrapperDesk.querySelector<HTMLDivElement>(HeaderWrapperDesk.cn.toSelector());

    // Илициализация всех слайдеров в сниппете товаров
    // eslint-disable-next-line
    SnippetProductSliderDesk.init();

    // Илициализация всех бегущих строк
    // eslint-disable-next-line
    TickerDesk.init();

    // Илициализация всех селектов
    // eslint-disable-next-line
    SelectDesk.init();

    // Илициализация всех главных баннеров
    MainBannerDesk.init();

    // Илициализация всех товарных слайдеров
    // eslint-disable-next-line
    ProductsSliderDesk.init();

    // Илициализация всех инстаграм слайдеров
    InstagramDesk.init();

    // Илициализация триггеров избранного
    FavoriteDesk.init();

    // Илициализация всех аккордионов
    // eslint-disable-next-line
    new Accordion();
    // eslint-disable-next-line
    new AccordionDesk();

    // Илициализация обертки шапки
    // eslint-disable-next-line
    new HeaderWrapperDesk(headerWrapper);

    // Илициализация подвала
    // eslint-disable-next-line
    new FooterDesk(FooterDesk.querySelector(FooterDesk.cn.toSelector()));

    // Инициализация баннеров над шапкой
    document.querySelectorAll<HTMLDivElement>(SlimBannerDesk.cn.toSelector())
        .forEach(slimBanner => new SlimBannerDesk(slimBanner));

    // Иницуиализация шапки
    // eslint-disable-next-line
    const header = new HeaderDesk(HeaderDesk.querySelector<HTMLDivElement>(HeaderDesk.cn.toSelector()));

    // Всплывашки для избранного
    // eslint-disable-next-line
    /* new FavoriteManagerDesk({
        modal: new FavoritePopoverDesk({ container: headerWrapper }),
        modalCn: HeaderPopoverDesk.cn,
        modalTriggerSelector: HeaderDesk.cn.setElem('tools-item').setMode({ favorite: true }).toSelector(),
        header
    }); */

    // Сниппет во всплывашке корзины
    // eslint-disable-next-line
    new CartPopoverSnippetDesk();

    // Инициализация cookie
    // eslint-disable-next-line
    new CookieDesk(CookieDesk.querySelector<HTMLDivElement>(CookieDesk.cn.toSelector()));

    const TEMPLATE = document.querySelector('[data-template]')?.getAttribute('data-template');

    if ([ 'cart', 'checkout' ].indexOf(TEMPLATE as string) < 0) {
        // Всплывашки для корзины
        // eslint-disable-next-line
        new CartManagerDesk({
            modal: new CartPopoverDesk({ container: headerWrapper }),
            modalCn: HeaderPopoverDesk.cn,
            modalTriggerSelector: HeaderDesk.cn.setElem('tools-item').setMode({ cart: true }).toSelector(),
            header
        });
    }

    const allowedTemplatesString = window.Site.settings.subscriptionModal.templates ?? '';
    const allowedTemplatesArray = allowedTemplatesString.replaceAll(' ', '').split(',').filter(Boolean);

    if (allowedTemplatesArray.indexOf(TEMPLATE as string) >= 0) {
        // Иницуиализация модального окна подписки
        // eslint-disable-next-line
        new SubscriptionModalDesk({
            template: 'subscription-modal-desk-template',
            opts: {
                title: window.Site.settings.subscriptionModal.title,
                descr: window.Site.settings.subscriptionModal.descr,
                photo: window.Site.settings.subscriptionModal.photo
            }
        });
    }

    if (TEMPLATE !== 'search') {
        // Илициализация поисковой строки в шапке
        // eslint-disable-next-line
        new SearchBoxManagerDesk({
            modal: new SearchBoxPopoverDesk({ container: headerWrapper }),
            modalCn: HeaderPopoverDesk.cn,
            modalTriggerSelector: HeaderDesk.cn.setElem('tools-item').setMode({ search: true }).toSelector(),
            headerWrapper
        });
    }

    switch (TEMPLATE) {
        case 'index':
            // eslint-disable-next-line
            new ShopPointsDesk(ShopPointsDesk.querySelector<HTMLDivElement>(ShopPointsDesk.cn.toSelector()));

            break;
        case 'collection':
            // eslint-disable-next-line
            new CollectionPage(headerWrapper);
            // eslint-disable-next-line
            new ScrollTopDesk(ScrollTopDesk.querySelector(ScrollTopDesk.cn.toSelector()));

            break;
        case 'cart':
            // eslint-disable-next-line
            const cart = new Cart(Cart.querySelector(Cart.cn.toSelector()));

            break;
        case 'page.favorites':
            // eslint-disable-next-line
            new FavoritePageDesk({
                rootNode: FavoritePageDesk.querySelector(FavoritePageDesk.cn.toSelector()),
                favoriteDesk: FavoriteDesk,
                productsSliderDesk: ProductsSliderDesk
            });

            break;
        case 'page':
            if (document.querySelector(ShopDesk.cn.toSelector())) {
                // eslint-disable-next-line
                new ShopDesk(ShopDesk.querySelector(ShopDesk.cn.toSelector()));
            }

            break;
        case 'account':
            const fn = async () => {
                const loginClient = await Client.get()
                    .then(response => response.status === 'ok' ? response : false)
                    .catch(() => false);

                if (loginClient) {
                    // eslint-disable-next-line
                    new AccountClientDesk(AccountClientDesk.querySelector(AccountClientDesk.cn.toSelector()), loginClient as ClientResponseInt);
                } else {
                    // eslint-disable-next-line
                    new AccountAuthDesk(AccountAuthDesk.querySelector(AccountAuthDesk.cn.toSelector()));
                }
            };

            fn();

            break;
        case 'page.manufacturing':
            // eslint-disable-next-line
            ManufacturinSliderDesk.init();

            break;
        case 'page.edit-subscriptions':
            // eslint-disable-next-line
            new EditSubscriptionPageWrapperDesk({
                form: '[data-page-edit-subscriptions]',
                errorSelector: '[data-edit-subscriptions-error]',
                resultSelector: '[data-edit-subscriptions-result]',
                resetChangesSelector: '[data-reset-changes]',
                loaderSelector: '[data-edit-subscriptions-loader]',
                clearVariantsAttr: 'data-clear-all',
                pointAttr: 'data-point-of-contact',
                topicAttr: 'data-topic',
                contentWrapperSelector: '[data-edit-subscription-content]',
                emptyWrapperSelector: '[data-edit-subscription-empty]'
            });
            break;
        case 'page.create-account':
            // eslint-disable-next-line
            const createAccount = new CreateAccountDesk(CreateAccountDesk.querySelector(CreateAccountDesk.cn.toSelector()));

            break;
        case 'search':
            // eslint-disable-next-line
            new SearchBoxDesk(document.querySelector<HTMLDivElement>(`${HeaderWrapperDesk.cn.toSelector()} ${SearchBoxDesk.cn.toSelector()}`))

            break;
        default:
            break;
    }

    switch (window.location.pathname) {
        case '/page/reviews':
            // eslint-disable-next-line
            new ReviewsPage(ReviewsPage.querySelector(ReviewsPage.cn.toSelector()));

            break;
        case '/page/stores':
            // eslint-disable-next-line
            new ShopPointsDesk(ShopPointsDesk.querySelector<HTMLDivElement>(ShopPointsDesk.cn.toSelector()));

            break;
        case '/page/welcomeonboard-man':
            // eslint-disable-next-line
            new SubscribeDesk(document.querySelector(SubscribeDesk.cn.setMode({ 'welcomeonboard-subscribe': true }).toSelector()) as HTMLFormElement);
            break;
        case '/page/welcomeonboard':
            // eslint-disable-next-line
            new SubscribeDesk(document.querySelector(SubscribeDesk.cn.setMode({ 'welcomeonboard-subscribe': true }).toSelector()) as HTMLFormElement);
            break;
        default:
            break;
    }
}

init();
